import React, { useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import "./PMHelpCenter.scss";
import {
  IMG_PM_WORKFLOW_SQUARE,
  IMG_PM_WORKFLOW_CIRCULAR,
  IMG_PM_HC_BABYHEAD1,
  IMG_PM_HC_BABYHEAD2,
  IMG_PM_HC_BABYHEAD3,
  IMG_PM_HC_PRODUCT_1,
  IMG_PM_HC_PRODUCT_2,
  IMG_PM_HC_PRODUCT_3,
  IMG_PM_HC_PRODUCT_4,
  IMG_PM_HC_PRODUCT_5,
  IMG_PM_HC_PRODUCT_6,
} from "../../PMConstants/PMImageConstants";
import Workflow from "../PMWorkflow/PMWorkflow";
const ParentHelpCenter = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <div className="content-wrapper">
      <div className="helpcenter-text">
        <div className="title">Parent Help Center</div>
        <div className="sub-title">All the Parent's FAQs are covered here</div>
      </div>
      <div className="helpcenter-wrapper">
        <Accordion className="helpcenter-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="helpcenter-accordion-title">
              1. What is plagiocephaly?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="helpcenter-accordion-details">
              Plagiocephaly (sometimes called deformational plagiocephaly or
              positional plagiocephaly) is a very common and highly treatable
              disorder. It causes a baby’s head to have a flattened appearance.
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion className="helpcenter-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="helpcenter-accordion-title">
              2. What is the incident rate of plagiocephaly?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="helpcenter-accordion-details">
              It affects 20-30% of newborn on moderate to severe scale every
              year in the US{" "}
              <a
                className="some-link"
                target="_blank"
                href="https://drive.google.com/file/d/12F4qOMuQD7F_JNXdRaDzD3iSOLSdjeG5/view"
              >
                Read here
              </a>
              .
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion className="helpcenter-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="helpcenter-accordion-title">
              3. What causes plagiocephaly?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="helpcenter-accordion-details">
              Plagiocephaly develops when an infant’s soft skull becomes
              flattened in one area, due to repeated pressure on one part of the
              head. Many babies develop plagiocephaly by sleeping regularly in
              one position. This condition occurs more often in premature
              infants whose skulls are especially pliable. This condition
              skyrocketed after the American Academy of Pediatrics (AAP)
              recommended that all babies sleep on their back to avoid sudden
              infant death syndrome (SIDS)
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion className="helpcenter-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="helpcenter-accordion-title">
              4. What are different types of plagiocephaly?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="helpcenter-accordion-details">
              There are three types of plagiocephaly:
              <div className="text-img-wrapper">
                <div className="img-container">
                  <img src={IMG_PM_HC_BABYHEAD1} alt="" className="fix_width" />
                </div>
                <div>
                  <span className="text-title">Plagiocephaly</span>: when the
                  head is asymmetry – this is the most common types
                </div>
              </div>
              <div className="text-img-wrapper">
                <div className="img-container">
                  <img src={IMG_PM_HC_BABYHEAD2} alt="" className="fix_width" />
                </div>
                <div>
                  <span className="text-title">Brachycephaly</span>: when the
                  head is flattened on the back
                </div>
              </div>
              <div className="text-img-wrapper">
                <div className="img-container">
                  <img src={IMG_PM_HC_BABYHEAD3} alt="" className="fix_width" />
                </div>
                <div>
                  <span className="text-title">Scaphocephaly</span>: this is the
                  least common and is when the skull is elongated
                </div>
              </div>
              <div className="text-wrapper">
                Children with plagiocephaly often see a plastic surgeon or
                neurosurgeon to make sure they do not have craniosynostosis.{" "}
                <a
                  className="some-link orange"
                  target="_blank"
                  href="https://www.childrenshospital.org/conditions-and-treatments/conditions/c/craniosynostosis"
                >
                  Craniosynostosis
                </a>{" "}
                is when the skull bones meld together and create an abnormal
                head shape that can look like plagiocephaly. Craniosynostosis is
                a rare condition (affecting 1 in 2000 newborns). However, unlike
                plagiocephaly, craniosynostosis requires surgery.
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion className="helpcenter-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="helpcenter-accordion-title">
              5. Repositioning therapy
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="helpcenter-accordion-details">
              Treatment for plagiocephaly usually includes special exercises,
              varying sleep position or wearing corrective headbands or using
              molding cups. 70-90% of plagiocephaly patients suffer from
              torticollis (stiffness in the neck muscles){" "}
              <a
                className="some-link"
                target="_blank"
                href="https://drive.google.com/file/d/1XsDYdFb1Yg2MWnHHFOcTrbmiag9XZNGr/view"
              >
                Read here
              </a>{" "}
              which may require physical therapy.
              <div className="mt10">
                Here is a comprehensive reference from Children’s’ Hospital of
                Atlanta:
                <div>
                  <a
                    className="some-link"
                    target="_blank"
                    href="/images/tummy-time-tools-english.pdf"
                  >
                    Click here                  </a>
                </div>
              </div>
              <div className="mt10">
                Here is the guidelines for the role of repositioning therapy by Congress of Neurological Surgeons (CNS) :
                <div>
                  <a
                    className="some-link"
                    target="_blank"
                    href="https://drive.google.com/file/d/1WzUBe-L0DJkAuJp6qu07pP1tFfXgLGjY/view?usp=drive_link"
                  >
                    Click here                  </a>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion className="helpcenter-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="helpcenter-accordion-title">
              6. How frequent should I use SoftSpot for measurement?
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="helpcenter-accordion-details">
              Since the skull is soft for a limited time (usually, before 6
              month), we recommend regular measurement starting at 1 month till
              6 month of age on a monthly basis.
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion className="helpcenter-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="helpcenter-accordion-title">7. Workflow</div>
          </AccordionSummary>
          <AccordionDetails>{/*
            <div className="helpcenter-accordion-details mt30">
              <div className="">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="worklow tabs"
                  className="workflow_tabs"
                >
                  <Tab label="Tele-health" className="first_tab" />
                  <Tab label="In Office" />
                </Tabs>
                <div role="tabpanel" className="workflow_tabpanel">
                  {value === 0 && (
                    <div className="workflow-square">
                      <div className="workflow-img-container">
                        <div className="text-row">
                          <div className="text-container">
                            <div className="title">1. Send Invite</div>
                            <div className="sub-title">
                              You invite your patients to SoftSpot
                            </div>
                          </div>
                        </div>
                        <div className="workflow-container">
                          <div className="text-container">
                            <div className="title">5. Visit</div>
                            <div className="sub-title">
                              You have a tele health or in-office visit
                              <div>(ICD-10 Q67.3)</div>
                            </div>
                          </div>
                          <div className="workflow-image-overlap">
                            <img alt="" src={IMG_PM_WORKFLOW_CIRCULAR} />
                          </div>
                          <div className="text-container ml10">
                            <div className="title">
                              2. Parents Upload Information
                            </div>
                            <div className="sub-title">
                              Parents upload photos/videos
                            </div>
                          </div>
                          <div className="workflow-image-text">
                            Takes upto 3 days
                          </div>
                        </div>
                        <div className="text-row top-padding-0">
                          <div className="text-container">
                            <div className="title">4. Notified for Result</div>
                            <div className="sub-title">
                              You will be notified when results are available
                            </div>
                          </div>
                          <div className="text-container">
                            <div className="title">3. Generate Report</div>
                            <div className="sub-title">
                              PediaMetrix generates the report
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {value === 1 && (
                    <div className="workflow-square">
                      <div className="workflow-img-container">
                        <div className="text-row">
                          <div className="text-container">
                            <div className="title">
                              1. Provider Upload Information
                            </div>
                            <div className="sub-title">
                              Provider upload photos/videos
                            </div>
                          </div>
                          <div className="text-container">
                            <div className="title">2. Generate Report</div>
                            <div className="sub-title">
                              Pediametrix generates the report
                            </div>
                          </div>
                        </div>
                        <div className="workflow-image-overlap">
                          <div className="square_text_upto_days">
                            Takes upto 3 days
                          </div>
                          <img alt="" src={IMG_PM_WORKFLOW_SQUARE} />
                        </div>
                        <div className="text-row">
                          <div className="text-container">
                            <div className="title">4. Visit</div>
                            <div className="sub-title">
                              You have a tele health or in-office visit
                              <div>(ICD-10 Q67.3)</div>
                            </div>
                          </div>
                          <div className="text-container">
                            <div className="title">3. Notified for Result</div>
                            <div className="sub-title">
                              You will be notified when results are available
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
                </div>*/}
            <Workflow />

          </AccordionDetails>
        </Accordion>

        <Accordion className="helpcenter-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="helpcenter-accordion-title">
              8. Other products for conservative management of plagiocephaly:
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="helpcenter-accordion-details product-info">
              {/*<div className="text-img-wrapper">
                <div className="img-container">
                  <img src={IMG_PM_HC_PRODUCT_1} alt="" className="fix_width" />
                </div>
                <div className="content-wrapper">
                  <span className="text-title">
                    <a
                      className="some-link"
                      target="_blank"
                      href="https://buy.stripe.com/28oaI8ee4buG2aY3cf"
                    >
                      Cap
                    </a>
                  </span>
                  : Required for all babies
                </div>
              </div>
              <div className="text-img-wrapper">
                <div className="img-container">
                  <img src={IMG_PM_HC_PRODUCT_2} alt="" className="fix_width" />
                </div>
                <div>
                  <span className="text-title">
                    <a
                      className="some-link"
                      target="_blank"
                      href="https://www.amazon.com/Babymoov-Lovenest-Patented-Syndrome-Prevention/dp/B06XSFV4R6/ref=sr_1_2?dchild=1&keywords=plagiocephaly+pillow&qid=1603070050&sr=8-2"
                    >
                      Plagiocephaly Pillows
                    </a>
                  </span>
                </div>
              </div>*/}
              <div className="text-img-wrapper">
                <div className="img-container">
                  <img src={IMG_PM_HC_PRODUCT_3} alt="" className="fix_width" />
                </div>
                <div>
                  <span className="text-title">
                    <a
                      className="some-link"
                      target="_blank"
                      href="https://www.amazon.com/Tortle-Elephant-Prevents-Syndrome-Torticollis/dp/B078J46NNQ/ref=sr_1_1?dchild=1&keywords=tortle&qid=1603070133&sr=8-1"
                    >
                      Tortle
                    </a>
                  </span>
                  : Adjustable Repositioning Beanie
                </div>
              </div>

              {/*<div className="text-img-wrapper">
                <div className="img-container">
                  <img src={IMG_PM_HC_PRODUCT_4} alt="" className="fix_width" />
                </div>
                <div>
                  <span className="text-title">
                    <a
                      className="some-link"
                      target="_blank"
                      href="https://www.amazon.com/Ubimed-Lifenest-Sleep-System-White/dp/B00D5DQ3OQ/ref=sr_1_9?dchild=1&keywords=plagiocephaly+mattress&qid=1603070314&sr=8-9"
                    >
                      Plagiocephaly Mattresses
                    </a>
                  </span>
                </div>
            </div>*/}
              <div className="text-img-wrapper mb20">
                <div className="img-container">
                  <img src={IMG_PM_HC_PRODUCT_5} alt="" className="fix_width" />
                </div>
                <div>
                  <span className="text-title">
                    Flat Head Syndrome Fix (
                    <a
                      className="some-link"
                      target="_blank"
                      href="https://www.amazon.com/Flat-Head-Syndrome-Fix-Plagiocephaly-ebook/dp/B017UPRUXW/ref=sr_1_2?dchild=1&keywords=plagiocephaly+book&qid=1603070605&sr=8-2"
                    >
                      Book
                    </a>
                    )
                  </span>
                </div>
              </div>
              <div className="text-img-wrapper mb20">
                <div className="img-container">
                  <img src={IMG_PM_HC_PRODUCT_6} alt="" className="fix_width" />
                </div>
                <div>
                  <span className="text-title">
                    Perfect Noggin Mattress (
                    <a
                      className="some-link"
                      target="_blank"
                      href="https://www.theperfectnoggin.com/shop-1"
                    >
                      Click Here
                    </a>
                    )
                  </span>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion className="helpcenter-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="helpcenter-accordion-title">9. Helmet therapy</div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="helpcenter-accordion-details">
              Studies suggest that almost 80% of plagiocephaly patients can
              avoid helmet but only if they diagnose and monitor it early and
              parent stick to the conservative treatment{" "}
              <a
                className="some-link"
                target="_blank"
                href="https://europepmc.org/article/med/25415272"
              >
                Read here
              </a>
              . For severe cases or after 6-7 month of age however, helmet
              therapy can be a viable treatment option. Please consult with your
              doctor.
              <div>
                Here is the guidelines for the role of helmet therapy by the Congress of Neurological Surgeons (CNS):
                <a
                  className="some-link"
                  target="_blank"
                  href="https://drive.google.com/file/d/1dYOLh8rmwRXgwP6kd51U79A8nqKUrfZy/view?usp=drive_link"
                >
                  Click here                  </a>
              </div>
            </div>

          </AccordionDetails>
        </Accordion>
        <Accordion className="helpcenter-accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="helpcenter-accordion-title">10. Terms and conditions highlights (please read carefully):</div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="helpcenter-accordion-details">
              <span>

                <b>Indication(s) for use:</b> <br />

                SoftSpot™ is intended to be used for cranial measurement for infants from 1 month up to 2 years of age in conjunction with other clinical methods. <br /> <br />

                <b>Warning:</b> SoftSpot™ is not intended to be used as a tool for preparing a helmet. <br /> <br />

                {/*<b>Special conditions for use statement(s):</b><br />

                    For prescription use only. <br /> <br />*/}

                <b>Device Description:</b><br />

                SoftSpot™ is a software that runs on a smartphone, tablet, PC, etc. and uses top view pictures of infant’s head to measure width, length, diagonals of the head, and head curvature circumference. After the photo is taken, the head contour is identified and then, based on the current standards of measuring infants’ head shape, characterizing parameters including Cranial Index or CI and Cephalic Vault Asymmetry Index or CVAI are calculated. The SoftSpot™ platform allows different users to upload or capture photos of the infant’s head. The user can be a provider/nurse (during well visits) or parents/guardians. Once data are collected, it will be analyzed and a pdf report is generated with the results and sent to the User's account. Users will be notified when the results are ready. Then, the parent can visit the provider (either in person or online) to discuss the results and next steps.
              </span>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default ParentHelpCenter;

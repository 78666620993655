import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Player, BigPlayButton } from "video-react";
import { Helmet } from "react-helmet";

import Button from "../../PMComponents/PMButton/PMButton";
import {
  PM_LOGO,
  DEMO_PAGE_IMAGE,
  SHOPPING_CART_IMAGE,
  PHY_REIMBURSEMENT
} from "../../PMConstants/PMImageConstants";
import "./PMDemo.scss";
import { Link } from "react-router-dom";

const Demo = (props) => {

  return (
    <>
      <Helmet>
        {/*Start of HubSpot Embed Code */}
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/3289469.js"></script>
        {/* End of HubSpot Embed Code */}
        {/*Start of Zendesk Embed Code */}
        <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=19450bbc-87ed-4529-aa07-a8c708ef478c">
        </script>
        {/* End of Zendesk Embed Code */}
      </Helmet>
      <Grid container spacing={0} className="demo-dashboard-container">
        <Grid item xs={12}>
          <div className="header-wrapper">
            <div>
              <a
                href="https://softspot.ai"
                target="_blank"
              >
                <img className="" src={PM_LOGO} title="" alt="Plagiocephaly Self Assessment App" />
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={24} sm={12}>
          <div className="demo-content-wrapper">
            <div className="demo-title">
              Is your practice missing a tool that accurately and quantitatively measures head shape?
            </div>
            <div className="subheadline">
              Reassure parents with an FDA-cleared cranial evaluation technology that can easily be done at the point-of-care using a smartphone
            </div>
            <div className="player-container">
              <div className="player-wrapper">
                <Player className="react-player" autoPlay={true} muted={true}>
                  <source src='videos/soft_spot_final_rev_nov_2022.mp4' />
                  <BigPlayButton position="center" />
                </Player>
              </div>
            </div>
            <div className="text-left">
              <div className="overview">
                Overview of benefits: </div>
              <ul className="overview-points">
                <li> &nbsp; Create up to $360/patient extra revenue</li>
                <li> &nbsp; Use in-office or have parents take photos from home</li>
                <li> &nbsp; Catch cranial deformities early and monitor progress</li>
                <li> &nbsp; Less cost for the patient and a better outcome</li>
                <li> &nbsp; Reassure parents about their baby’s health</li>

              </ul>
            </div>
            {/*<div className="demo-image-wrapper">
              <img
                className="demo-dashboard-mission-image"
                src={PHY_REIMBURSEMENT}
                title=""
                alt="Reimbursement For Providers"
              />
            </div>*/}
          </div>
        </Grid>
        <Grid item xs={24} sm={12} className="">
          <div className="demo-content-wrapper">
            <div className="offer-demo-title">Offer</div>
            <div className="subheadline">
              Click below to schedule a 15-minute call to learn more on how SoftSpot benefits your practice.
            </div>
            <Button
              text="Schedule 15-Minute Demo"
              additionalClass="schedule-button"
              onClick={() => {
                //window.open("https://meetings.hubspot.com/reza-seifabadi?utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz-8pPHqFcMYvhw7wlly6QnvihWx_ubbwkLoMOo0XshOJpwXMJOTSUWfuX-bNgHQqzzMqhQep", "_blank");
                window.open("https://meetings.hubspot.com/mehrab-ajalli", "_blank");
              }}
            /> {/*&nbsp; &nbsp;
            <Button
              text="Shop"
              additionalClass="shop-button"
              onClick={() => {
                window.open("/shop", "_blank");
              }}
            />*/}
            {/*<div className="subheadline">
              Ready to purchase? Use this coupon to get <b className="discount-text">46% off for a limited time: PN2022</b>
            </div>
            <div>
              <Button
                text="Purchase from Here"
                additionalClass="schedule-button"
                onClick={() => {
                  window.open("https://buy.stripe.com/28oaI8ee4buG2aY3cf", "_blank");
                }}
              />
              </div>*/}
          </div>
          <div className="demo-image-wrapper">
            <img
              className="demo-dashboard-mission-image"
              src={DEMO_PAGE_IMAGE}
              title=""
              alt="Softspot App For Cranial Measurements"
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Demo;
import React from "react";
import Grid from "@material-ui/core/Grid";
import { IMG_PMLOGO } from "../../PMConstants/PMImageConstants";
import { Helmet } from "react-helmet";

const TermsOfService = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://softspot.ai/terms-and-conditions" />
      </Helmet>
      <div className="page-container">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="content-wrapper text-content-wrapper">
              <div className="">
                <p align="center"><h1>Terms & Conditions</h1></p>
                <p><i>Note: Our chat system is designated for technical support only. While we prioritize data security, please refrain from sharing protected health information (PHI) over chat, as the chat system is not currently part of our HIPAA compliant platform.</i></p>

                <p><b>Indication(s) for use:</b></p>

                <p>SoftSpot&trade; is intended to be used for cranial measurement for infants from 1 month up to 2 years of age

                  in conjunction with other clinical methods.</p>

                <p><b>Warning:</b> SoftSpot&trade; is not intended to be used as a tool for preparing a helmet.</p>

                <p></p>

                {/*<p><b>Special conditions for use statement(s):</b></p>

  <p>For prescription use only.</p>*/}

                <p></p>

                <p><b>Device Description:</b></p>

                <p>SoftSpot&trade; is a software that runs on a smartphone, tablet, PC, etc. and uses top view pictures of infant&rsquo;s

                  head to measure width, length, diagonals of the head, and head curvature circumference. After the photo

                  is taken, the head contour is identified and then, based on the current standards of measuring infants&rsquo;

                  head shape, characterizing parameters including Cranial Index or CI and Cephalic Vault Asymmetry

                  Index or CVAI are calculated. The SoftSpot&trade; platform allows different users to upload or capture photos

                  of the infant&rsquo;s head. The user can be a provider/nurse (during well visits) or parents/guardians. Once data are collected, it will be analyzed and a pdf report is generated with the results and

                  sent to the User&rsquo;s account. Users will be notified when the results are ready. Then, the parent can

                  visit the provider (either in person or online) to discuss the results and next steps.</p>

                <p></p>

                <p></p>

                <p>These terms and conditions are solely for persons in the US. For persons who may use our service for testing or other purposes from outside the US, terms and conditions will be communicated on a case-by-case basis. If you reside in the US , you are entering into this Contract PediaMetrix Inc. (&ldquo;we&rdquo; or &ldquo;us&rdquo;), a Delaware corporation with its principal place of business at 155 Gibb St, Rockville, Maryland 20850 designs and develops pediatric health solutions using the latest computer-vision and mobile technology. These Terms and Conditions (&ldquo;Terms&rdquo;) govern your use of SoftSpot&trade;, the software embedded in SoftSpot&trade; the SoftSpot&trade; web application, the SoftSpot&trade; mobile application (the &ldquo;App&rdquo;), and other SoftSpot&trade; services (collectively, the &ldquo;Services&rdquo;).</p>

                <p></p>

                <p>Except as otherwise agreed in a fully executed written contract, these Terms are hereby incorporated into, and made a part of any use by you of software, the App, or any service from PediaMetrix Inc. You are entering into this agreement (this &ldquo;Agreement&rdquo;) and you agree to be bound by these Terms. IF YOU DO NOT ACCEPT THESE TERMS, DO NOT CREATE AN ACCOUNT OR USE SoftSpot&trade; Services.</p>

                <p></p>

                <p>Our Privacy Policy (available at https://www.pediametrix.com/privacy-policy) sets out how your personal information is used by us, which you can access as part of the account registration process, through the App and on our websites. You must be at least 18 years of age to offer, perform or procure these services.</p>

                <p></p>

                <p><b>1. Rights Granted.</b> We grant to you and you accept a non-exclusive, personal, non-transferable, limited right to access and use the Services, and a non-exclusive, personal, non-transferable, limited license to use any software furnished by us for access to or use of the Services during the Term, subject to your full compliance with the Terms. You will not: (a) use the Services for time-sharing, rental or service bureau purposes; (b) make the Services, in whole or in part, available to any other person, entity or business; (c) copy, reverse engineer, decompile or disassemble the Services, in whole or in part, or otherwise attempt to discover the source code to the software used by the Services; or (d) modify, combine, integrate, render interoperable, or otherwise access for purposes of automating data conversion or transfer, the Services or associated software with any other software or services not provided or approved by us. YOU WILL OBTAIN NO RIGHTS TO THE SERVICES EXCEPT FOR THE LIMITED RIGHTS TO USE THE SERVICES EXPRESSLY GRANTED BY THIS AGREEMENT.</p>

                <p></p>

                <p><b>2. Hardware Ownership.</b> When applicable, &ldquo;Hardware&rdquo; includes phone or a tablet computer, or other devices purchased with SoftSpot&trade; pre-installed. Unless otherwise agreed in writing, at all times, you will be the owner of the Hardware. The device should only be used by the authorized people; any damage to the device, security issues, or data breaches is solely your responsibility. If any repair, re-installment, or other support services not included in this agreement are required, we will charge you to provide those services.</p>

                <p></p>

                <p><b>3. Delivery.</b> When applicable, we will deliver, implement and make devices with SoftSpot&trade; installed available to you at the Facilities named in the Invoice, and you agree to accept and use SoftSpot&trade; at the named Facilities, and no other location. We will provide training on how to use SoftSpot&trade;.</p>

                <p></p>

                <p><b>4. Fees and Charges.</b>  You agree to pay us the Services Fee and taxes in the amounts and according to the schedule set forth in the Invoice. All fees are due within thirty (30) days of invoice date and are payable in U.S. dollars. Past-due amounts may be subject to finance charges of the lesser of: (a) 1.5% per month, or (b) the maximum rate allowed by law. PediaMetrix Inc. may change its Products, Services and support offerings from time to time. Following the initial Term and again following each successive Term, PediaMetrix Inc. may increase its fees. PediaMetrix Inc. will notify you at least thirty (30) days in advance of any change in Service fees, <b>including changes to the clinical professionals' subscription fee. The subscription price is subject to change, and we will send prior notification to the customer regarding any price adjustments. Unlimited use is defined as fair usage based on the average patient volume per provider in the US. </b> If you request additional on-site training or other services from us beyond the scope of this Agreement, you agree to pay our out-of-pocket expenses and standard charges. Taxes, if any, resulting from this Agreement (except taxes on our income) will be your responsibility to pay. You are also responsible for the costs and expenses, including reasonable attorney’s fees we incur to collect any past-due amounts under this Agreement. PediaMetrix Inc. reserves the right to suspend user logins if your account becomes thirty (30) days past due until such time when your invoices (past-due and current) are paid. All amounts are payable in U.S. dollars.</p>

                <p></p>

                <p><b>5. Our services</b></p>

                <p>We offer our services through our app SoftSpot&trade;, and the websites and through communication methods such as e-mail or chat. Our services include</p>

                <ul><li>Infant&rsquo;s digital cranial assessment tool</li>

                  <li>as well as aiding in treatment planning and progress tracking;</li>

                  <li>access to the health data held by us; and</li>

                  <li>Access to other digital health tools that provide information on health and lifestyle</li></ul>

                <p>You should not use our services in an emergency, or if you have a medical condition that you know requires physical examination. If you experience a medical emergency, please call the local emergency service in your area.</p>

                <p></p>

                <p><b>6. Third party services </b></p>

                <p><b>6.1 </b>The Services may include certain third-party software, hardware, and applications that may require that you enter into separate agreements with third parties. You will comply with and, upon request, execute, any agreements that may be required for the use of such software or services, and hereby agree to comply with the terms of any license or other agreement relating to third-party products included in the Services or made accessible to you through the Services. Additionally, your use of the Services or of such third-party products or services will constitute your agreement to be bound by the terms of all licensing, subscription and similar agreements relating to such use.</p>

                <p></p>

                <p><b>6.2.</b> We are not responsible for the quality or efficacy of any Third-Party Services, or their information privacy or security practices, and we have no responsibility for the information, goods or services offered or provided by the operators of such Third-Party Services, or for the manner in which they conduct their operations. Your use of Third-Party Services and the materials, goods and services offered by them is entirely at your own risk and is subject to the terms of use of the third parties operating or providing them. You should review the applicable terms of use and privacy policies of any Third-Party Services, since they are subject only to the agreements you have with the operators of such Third-Party Services, and not covered by this Agreement. Further, the Third-Party Services may use Your Information in a way that we would not. You further acknowledge that your use of any Third-Party Services is on an &ldquo;as-is&rdquo; basis.</p>

                <p></p>

                <p><b>7. Services Warranty.</b> All warranties are personal to you and may not be transferred or assigned to a third-party, including an affiliate. All warranties are facility specific and do not transfer if the Services are moved to another facility unless we conduct such a move. The warranties described in this Agreement exclude any stand-alone third-party goods that may be acquired or used with the Services.</p>

                <p></p>

                <p><b>7.1.</b> Warranty for Hardware. We cannot provide warranties on the sold hardware as they are third party devices covered by the third-party warranty terms.</p>

                <p></p>

                <p><b>7.2.</b> Warranty for Software. &ldquo;Software&rdquo; means the software embedded in SoftSpot&trade; mobile App. We represent and warrant that the Software will conform to its Specifications for a period of twelve (12) months after installation. We shall not be liable at all for the functionality or quality of plug-ins or other auxiliary programs designed to work together with the Software, or for the interoperability of such programs together with the Software.</p>

                <p></p>

                <p><b>7.3.</b> EXCEPT FOR THE WARRANTIES ABOVE, WE MAKE NO, AND HEREBY DISCLAIMS ANY REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE HARDWARE OR SOFTWARE, WHETHER AS TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WARRANTIES ARISING FROM COURSE OF DEALING OR USAGE OR TRADE OR ANY OTHER MATTER. NO EMPLOYEE, REPRESENTATIVE OR AGENT OF OURS HAS ANY AUTHORITY TO BIND US TO ANY AFFIRMATION, REPRESENTATION OR WARRANTY EXCEPT AS STATED IN SECTIONS 6.1 AND 6.2.</p>

                <p></p>

                <p><b>7.4.</b> Exclusions from Warranty Coverage. The foregoing warranties do not apply to the extent a non-conformance is due to (i) abuse, misuse, neglect, negligence, accident, improper storage, or use contrary to the Documentation or Specifications, (ii) improper handling, installation, maintenance, or repair (other than if performed by our personnel), (iii) unauthorized alterations, (iv) Force Majeure events, or (v) use with a third party&rsquo;s good or service not provided by us (unless the Services&rsquo; Documentation or Specifications expressly state such third party&rsquo;s good is for use with the Service).</p>

                <p></p>

                <p><b>7.5.</b> Procedure for Warranty Coverage. In order to be eligible for repair or replacement under this warranty you must (i) contact our support department to report the non-conformance within five (5) business days, (ii) cooperate with us in confirming or diagnosing the non-conformance, and (iii) return the Services, transportation charges prepaid to us following our instructions or, if agreed, grant our authorized repair personnel access to the Services in order to confirm the non-conformance and make repairs.</p>

                <p></p>

                <p><b>7.6.</b> Sole Remedy under Warranty. We will, at our option, repair or replace non-conforming Services that are covered by this warranty, provided that we can reasonably identify and confirm such nonconformance.</p>

                <p></p>

                <p><b>8. Indemnification</b></p>

                <p></p>

                <p><b>8.1.</b> We will defend any claim brought against you by any third party alleging that SoftSpot&trade; infringes, misappropriates or violates that party&rsquo;s patent rights, trademark rights, copyright rights or rights under trade secret laws, each as recognized in the United States. We will pay all damages and costs awarded against you by judgment or in settlement in connection with such a claim as well as any costs incurred by you in response to a request by us to assist with the defense of the claim, including attorney fees. We have no responsibility or liability for such claims to the extent such claims are based upon: (a) any use of any part of SoftSpot&trade; after notification to discontinue use; (b) modifications of SoftSpot&trade; not made or authorized by us; (c) use of SoftSpot&trade; with other software that was not provided or authorized by us; or (e) any use in violation of this Agreement or misuse or unauthorized use of SoftSpot&trade;. If a claim within the scope of this Section is made or appears likely to be made, we may, in our sole discretion and at our expense enable you to continue to use the unaffected portions of SoftSpot&trade;; or replace or modify SoftSpot&trade; so that it is non-infringing and substantially equivalent in function to the allegedly infringing components. If we determine none of these alternatives is reasonably available, we may terminate this Agreement.</p>

                <p></p>

                <p><b>8.2.</b> You will defend, indemnify and hold us harmless from any claim brought against us as a result of (a) a claim involving you, your dependent, or your patient&rsquo;s medical treatment and care of a patient; (b) your violation of law or a regulation; (c) your actual or alleged infringement of a third party&rsquo;s intellectual property rights (except if covered by our indemnification obligations in Section 7.1); or (d) your business activities. You agree to pay all judgments or settlements resulting from such a claim against us, plus reasonable attorney fees we incur in connection with the claim.</p>

                <p></p>

                <p><b>9.Intended Users</b></p>

                <p> <b>9.1.</b> Parents or caregivers can only use SoftSpot&trade; for the intended registered child they are authorized to use for with the consent of the child&rsquo;s legal guardian. Any use of the SoftSpot&trade; with a child not registered, without consent of the child&rsquo;s legal guardian, or under an incorrect or another child&rsquo;s name is against the law and the access to the account will be terminated immediately.</p>

                <p></p>

                <p><b>9.2.</b> Healthcare providers may only use SoftSpot&trade; for their patients registered in SoftSpot&trade; and after they sign the Business Associate Agreement with PediaMetrix Inc. according to HIPAA requirements. They may not provide access to or use the Services for the benefit of third parties or make commercial use of the Services other than in his or her capacity as a healthcare provider, but he or she may use the Services for his or her personal use or in his or her capacity as a healthcare provider for another user subject to this Agreement.</p>

                <p></p>

                <p><b>9.3.</b> By accessing and using the Services, you represent and warrant that (a) any and all registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information; and (c) your use of the Services will comply with and does not violate any applicable law, regulation, order or guideline. If you are a parent or caregiver, you represent and warrant that you understand that SoftSpot&trade; is a supporting, educational tool and not a medical diagnosis tool and that the information provided by SoftSpot&trade; should only be used by the child&rsquo;s medical provider and accompany their professional judgement. In addition, if you are a healthcare provider, by accessing using the Services you represent and warrant that (a) you are and will be for so long as you use the Services duly licensed, certified, credentialed, accredited, permitted and authorized in accordance with all applicable laws and regulations to engage in your professional activities; (b) you understand that SoftSpot&trade; is a supporting, educational tool to be used in your professional judgment and it is not an alternative for providing your professional duties; (c) the information provided by SoftSpot&trade; does not constitute diagnoses; an (d) the information provided by SoftSpot&trade; must be correlated with other clinical data as required by professional standards in your discipline; and (e) you are fully responsible for your use of the SoftSpot&trade; and information you make available by means of SoftSpot&trade;.</p>

                <p></p>

                <p><b>10. Disclaimers </b></p>

                <p><b>10.1.</b> WE CANNOT ACCEPT ANY LIABILITY WHATSOEVER IN RESPECT TO ANY CONTENT, INCLUDING INFORMATION REGARDING HEALTH, DIAGNOSIS, TREATMENT, MEDICAL ADVICE, AND OTHERWISE, WHICH IS PROVIDED BY THIRD PARTIES AND/OR ANY OTHER USERS OF THE SERVICES. ANY ACTIONS YOU TAKE BASED ON INFORMATION PROVIDED BY THE SERVICES ARE TAKEN AT YOUR SOLE RISK AND WE WILL NOT ACCEPT ANY LIABILITY IN RESPECT THEREOF. YOU SHOULD ALWAYS CHECK ANY INFORMATION PROVIDED THROUGH THE SERVICES TO ENSURE ITS ACCURACY. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SERVICES OR ANY INFORMATION, CONTENT, MATERIALS OR PRODUCTS INCLUDED OR REFERENCED THEREIN. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES&rsquo; RIGHTS AND FITNESS FOR A PARTICULAR PURPOSE. YOU ACKNOWLEDGE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. WE DISCLAIM ANY IMPLIED OR STATUTORY WARRANTIES (I) REGARDING THE SECURITY, ACCURACY, RELIABILITY, TIMELINESS AND PERFORMANCE OF THE SERVICES; OR (II) THAT THE SERVICES WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED; OR (III) REGARDING THE PERFORMANCE OF OR ACCURACY, QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION PROVIDED BY THE SERVICES.</p>

                <p></p>

                <p><b>10.2</b> YOU MAY NOT ATTEMPT TO GAIN UNAUTHORIZED ACCESS TO THE SERVICES, APPS OR WEBSITES. YOU WOULD NEED TO DOWNLOAD THE APPS VIA OUR WEBSITE, WHICH GIVE YOU ACCESS TO OUR SERVICES. PLEASE DO NOT INSTALL OUR APPS ON A DEVICE WHOSE OPERATING SYSTEM HAS BEEN COMPROMISED OR MAY COMPROMISE THE SECURITY OF YOUR PERSONAL INFORMATION. YOU MAY NOT USE THE WEBSITES AND THE APPS FOR COMMERCIAL PURPOSES OR TO ADVERTISE MEDICAL SERVICES.</p>

                <p></p>

                <p><b>10.3.</b> IF YOU ARE A HEALTHCARE PROVIDER, YOU ACKNOWLDGE THAT SoftSpot&trade; IS A SUPPORTING TOOL AND IS NOT AN ALTERNATIVE FOR PERFORMING PROFESSIONAL DUTIES. YOU MUST INTEGRATE HEALTH INFORMATION INTO THE GENERAL CLINICAL RECORD FOR INDIVIDUALS UNDER YOUR SUPERVISION AND CORRELATE WITH OTHER CLINICAL DATA AS REQUIRED BY PROFESSIONAL STANDARDS IN YOUR DISCIPLINE. USE OF DATA ACCESSED BY MEANS OF SoftSpot&trade;,AS WELL AS TREATMENT DECISIONS BASED ON SUCH INFORMATION, ARE THE SOLE RESPONSIBILITY OF THE HEALTHCARE PROVIDER AND WE ASSUME NO LIABILITY IN RESPECT THEREOF.</p>

                <p></p>

                <p><b>10.4</b> WE ARE RESPONSIBLE TO IMPLEMENT BEST PRACTICES THAT PREVENT UNAUTHORIZED ACCESS TO YOUR DATA IN OUR POSSESSION, OR FOR UNAUTHORIZED ACCESS TO, ALTERATION, THEFT, CORRUPTION, LOSS OR DESTRUCTION OF YOUR DATA IN OUR CLOUDS OR STORAGE, WHETHER BY FRAUDULENT MEANS OR DEVICES OR ANY OTHER UNAUTHORIZED MEANS. WE USE HIGH STANDARD SECURITY MEASURES REGARDING PROCESSING OF YOUR DATA. BUT IF YOU MADE DATA AVAILABLE IN PUBLIC, OR SHARE DATA WITH SOMEONE WHO IS NOT UNDER CONTROL OF US, WE WONT BE LIABLE FOR THIS DATA ACCESS. ALSO, WE ARE NOT RESPONSIBLE FOR ANY UNAUTHORIZED ACCESS TO YOUR DATA AT YOUR OR YOUR HEALTH PROVIDER'S FACILITIES OR EQUIPMENT USING OUR SERVICE OR FOR UNAUTHORIZED ACCESS TO, ALTERATION, THEFT, CORRUPTION, LOSS, OR DESTRUCTION OF YOUR DATA FILES, PROGRAMS, PROCEDURES, OR INFORMATION THROUGH THEIR SERVICES WHETHER BY ACCIDENT, OR ANY OTHER MEANS.WE ARE ALSO RESPONSIBLE TO IMPLEMENT BEST PRACTICES FOR VALIDATING THE ACCURACY OF ALL OUTPUT AND REPORTS, AND FOR PROTECTING YOUR DATA AND PROGRAMS FROM LOSS BY IMPLEMENTING APPROPRIATE SECURITY MEASURES. NEVERTHELESS, WE ARE NOT LIABLE FOR DAMAGES OCCASIONED BY INCORRECT INPUTS, OR INCORRECT DATA FILES RESULTING FROM PROGRAMMING ERROR, OPERATOR ERROR, EQUIPMENT MALFUNCTION, OR THE USE OF THIRD-PARTY SOFTWARE.</p>

                <p></p>

                <p><b>10.5.</b> THE SoftSpot&trade;SYSTEM REQUIRES STRONG, STABLE NETWORK CONNECTIVITY FOR DATA SYNCHRONIZATION BEFORE ATTEMPTING TO SWITCH BETWEEN OFFLINE AND ONLINE MODES. IF YOU HAVE WEAK, UNSTABLE CONNECTIVITY, SYNCHRONIZATION MAY FAIL AND MAY RESULT IN TEMPORARY OR, IN EXTREME CASES, PERMANENT LOSS OF DATA. PEDIAMETRIX IS NOT LIABLE FOR LOSS OF DATA AS A RESULT OF DEVICE USE WITHOUT MEETING SYSTEM REQUIREMENTS.</p>

                <p></p>

                <p><b>10.6.</b> SoftSpot&trade; IS AUTOMATICALLY SYNCHRONIZED WITH PediaMetrix Inc&rsquo;s SECURE ONLINE DATABASE WHEN OPERATED IN THE &ldquo;ONLINE MODE.&rdquo; DO NOT WORK IN THE &ldquo;OFFLINE MODE&rdquo; FOR A PERIOD OF TIME EXEEDING FOURTY EIGHT (48) HOURS AS IT MAY RESULT IN PROBLEMS WITH SYNCHRONIZATION INVOLVING LARGE AMOUNT OF DATA AND MAY RESULT IN TEMPORARY OR, IN EXTREME CASES, PERMANENT LOSS OF DATA. PEDIAMETRIX IS NOT LIABLE FOR LOSS OF DATA AS A RESULT OF DEVICE USE IN OFFLINE MODE FOR AN EXTENDED PERIOD OF TIME.</p>

                <p></p>

                <p><b>10.7.</b> BY USING SoftSpot&trade;, YOU EXPRESSLY AGREE AND ACKNOWLEDGE THAT PEDIAMETRIX INC SHALL NOT BE RESPONSIBLE FOR ANY OF THE FOLLOWING: (A) PERFORMANCE LIMITATIONS AND/OR FAILURES EXPERIENCED IN CONNECTION WITH ANY WIRELESS OR WI-FI SERVICE USED TO ACCESS THE SERVICE; (B) DATA TRANSMITTED THROUGH WIRELESS ACCESS THAT MAY BE INTERCEPTED BY UNAUTHORIZED PERSONS; OR (3) FAILURE TO PHYSICALLY SECURE AND/OR MONITOR THE MOBILE DEVICE OR TO PROTECT PASSWORDS, RESULTING IN UNAUTHORIZED ACCESS TO THE DATA AND/OR YOUR PEDIAMETRIX ACCOUNT OR YOUR PATIENT INFORMATION.</p>

                <p></p>

                <p><b>10.8.</b> FOLLOW INSTRUCTIONS FOR USE PROVIDED IN USER MANUAL AND DURING TRAINING, TUTORIAL. MEASUREMENT PERFORMANCE CAN BE AFFECTED IF INSTRUCTIONS ARE NOT FOLLOWED.</p>

                <p></p>

                <p><b>10.9.</b> YOU ARE RESPONSIBLE FOR ENSURING THAT ONLY AUTHORIZED USERS ARE ALLOWED TO ACCESS PROTECTED HEALTH INFORMATION (PHI). PEDIAMETRIX INC PROVIDES A SYSTEM OF ENCRYPTION FOR SECURING TRANSMISSIONS BETWEEN MOBILE DEVICES, BROWSERS AND OUR VIRTUAL PRIVATE NETWORK IN THE CLOUD. PEDIAMETRIX, INC. IS NOT RESPONSIBLE FOR INCIDENTS INVOLVING UNAUTHORIZED ACCESS.</p>

                <p></p>

                <p><b>10.10.</b> USERNAMES AND PASSWORDS: EACH AUTHORIZED INDIVIDUAL WHO IS REGISTERED TO USE THE SoftSpot&trade; PRODUCT WILL HAVE A USER NAME AND PASSWORD. PASSWORDS ARE THE PRIVATE PROPERTY OF THE USER WHO IS RESPONSIBLE THE CONFIDENTIALITY OF THE INDIVIDUAL USERNAME AND PASSWORD. PEDIAMETRIX, INC. IS NOT RESPONSIBLE FOR INCIDENTS INVOLVING THE USE OF THE SYSTEM BY THOSE OTHER THAN THE SPECIFIC AUTHORIZED USER.</p>

                <p></p>

                <p><b>10.11.</b> PEDIAMETRIX INC UTILIZES SECURITY POLICIES AND TECHNOLOGY IN PLACE TO PROTECT AGAINST THE LOSS, MISUSE, AND ALTERATION OF THE INFORMATION UNDER PEDIAMETRIX&rsquo;S CONTROL. THIS INCLUDES USER PERSONAL INFORMATION. WE CANNOT HOWEVER PROTECT AGAINST USER MISUSE, LOSS, OR ALTERATION OF INFORMATION.</p>

                <p></p>

                <p><b>10.12.</b> ALTHOUGH PEDIAMETRIX MAKES EVERY REASONABLE ATTEMPT TO SECURE ALL THE INFORMATION WITHIN ITS CONTROL, THERE IS ALWAYS SOME RISK IN TRANSMITTING INFORMATION ACROSS THE INTERNET. WE CANNOT GUARANTEE THAT THE PERSONAL INFORMATION WE COLLECT WILL NEVER BE DISCLOSED IN SOME MANNER NOT CONSISTENT WITH OUR PRIVACY POLICY. WE ALSO CANNOT PROTECT AGAINST AND ASSUME NO LIABILITY FOR USER MISUSE, LOSS, OR ALTERATION OF ANY EDITABLE CONTENT.</p>

                <p></p>

                <p><b>10.13.</b> WE DETECT AND USE THE IP ADDRESS OF VISITORS TO THE PORTAL IN ORDER TO ASSIST US WITH DIAGNOSTICS, SERVICE SOLUTONS THE ADMINISTRATION OF OUR SYSTEMS.</p>

                <p></p>

                <p><b>10.14.</b> PEDIAMETRIX INC AUTOMATICALLY COLLECTS AND STORES INFORMATION ABOUT EACH TRANSACTION: YOUR SESSION, THE DATE, TIME AND LENGTH OF EACH USE AND ALL TRANSACTIONS PERFORMED WILL BE COLLECTED, USED AND RETAINED FOR AUDIT TRAIL AND ISSUES DIAGNOSIS PURPOSES. IN THE INTERESTS OF PRIVACY, HOWEVER, THIS INFORMATION WILL NOT BE SHARED WITH ANYONE.</p>

                <p></p>

                <p><b>10.15.</b> WE MAY CONTRACT WITH OTHER COMPANIES OR INDIVIDUALS TO HELP US PROVIDE SERVICES, SUCH AS DEVELOPMENT OR MAINTENANCE OF THE SYSTEMS. WE REQUIRE OTHERS TO COMPLY WITH THE PRIVACY POLICY AND LIMIT THEIR ACCESS TO ONLY THE INFORMATION THAT IS NECESSARY TO ACCOMPLISH THE TASK AT HAND AND NOTHING MORE: PERSONAL INFORMATION WILL NOT BE PROVIDED TO ANY THIRD PARTIES OTHER THAN FOR SERVICES BEING PROVIDED.</p>

                <p></p>

                <p><b>10.16.</b> IF A BREACH OF PATIENT HEALTH INFORMATION OCCURS OR IF THERE ARE ANY NOTICED SUSPICIOUS ACTIVITIES AT YOUR SITE, YOU MUST NOTIFY PEDIAMETRIX INC FOLLOWING THE DISCOVERY OF THE BREACH WITHOUT UNREASONABLE DELAY AND NO LATER THAN 3 DAYS FROM THE DISCOVERY OF THE BREACH. TO THE EXTENT POSSIBLE, YOU SHOULD PROVIDE PEDIAMETRIX INC WITH THE IDENTIFICATION OF FACILITY, ACCOUNT AND EACH INDIVIDUAL AFFECTED BY THE BREACH AS WELL AS ANY OTHER AVAILABLE INFORMATION REQUIRED TO BE PROVIDED.</p>

                <p></p>

                <p><b>10.17.</b> WHERE APPLICABLE, IF A DEVICE IS LOST YOU MUST REPORT TO PEDIAMETRIX INC IMMEDIATELY</p>

                <p></p>

                <p><b>10.18.</b> YOU AGREE TO FOLLOW STANDARD HARDWARE OPERATING CONDITIONS, FOR EXAMPLE APPLE LISTS THE NORMAL TEMPERATURE OPERATING RANGE FOR THE IPAD AS 32&deg; &ndash; 95&deg; F. USING THE DEVICE OUTSIDE OF THIS RANGE MAY CAUSE THE SYSTEM TO BECOME COMPLETELY UNUSABLE UNTIL THE INTERNAL TEMPERATURE OF THE DEVICE IS WITHIN THE NORMAL OPERATING RANGE. YOU AGREE TO FOLLOW RECOMMENDATIONS AND GUIDELINES FOR CLEANING THE DEVICE, ITS CAMERA, AND DISPLAY AS SPECIFICED IN THE DEVICE&rsquo;S USER MANUAL.</p>

                <p></p>

                <p><b>11.Term; Modification; Termination </b></p>

                <p><b>11.1.</b> Term. The initial Term of this Agreement shall commence on the date you register for the Services and continue for a period of one (1) year, and thereafter automatically continue until terminated as provided in this Section.</p>

                <p></p>

                <p><b>11.2.</b> Modification. We may update or change the Services or the terms set forth in this Agreement from time to time and recommend that you review the Agreement on a regular basis. You understand and agree that your continued use of the Services after the Agreement has been updated or changed constitutes your acceptance of the revised Agreement. Without limiting the foregoing, if we make a change to the Agreement that materially affects your use of the Services, we may post notice or notify you via email or our website of any such change.</p>

                <p></p>

                <p><b>11.3.</b> Termination upon Notice. Either party may terminate this Agreement at any time without cause upon thirty (30) days&rsquo; prior written notice to the other Party.</p>

                <p></p>

                <p><b>11.3.1</b> you can terminate your agreement with us:</p>

                <p>if the Services are unavailable for reasons beyond our control or for technical reasons as set out these terms;</p>

                <p>if you do not agree to any material change, we propose to make to these Terms; or</p>

                <p>if we have failed to repair or re-perform services that do not meet the standards set out these Terms.</p>

                <p></p>

                <p><b>11.3.2.</b> You will be responsible for any device(s) purchased for the use of SoftSpot&trade;even if you bought it through PediaMetrix. PediaMetrix does not accept return of hardware per termination of agreement.</p>

                <p></p>

                <p><b>11.3.3.</b> You subsequently choose to cancel the subscription services within the cancellation period, we reduce the amount of the refund, that the consumer will have to bear the cost of returning the goods in case of withdrawal.</p>

                <p></p>

                <p><b>11.3.4</b> In the case of the supply of service made to you personalized, No refund will be made.</p>

                <p></p>

                <p><b>12. Personally Identifiable Health Information (&ldquo;PHI&rdquo;)</b></p>

                <p> <b>12.1. </b>Your use of the Services may provide us access to electronic medical records and individually identifiable health information that is subject to PHI. We will meet our legal obligations under HIPAA with respect to the PHI and will perform our duties as your business associate, as defined in 45 CFR 160.103 of the Health Insurance Portability and Accountability Act (&ldquo;HIPAA&rdquo;) in accordance with the PediaMetrix Inc. Business Associate Agreement (&ldquo;BAA&rdquo;) delivered with and incorporated into this Agreement.</p>

                <p></p>

                <p><b>12.2.</b> When delivered, you will have exclusive custody and possession of the Hardware and are solely responsible for the protection, preservation and security of the Hardware and of the data stored within the Hardware, including, but not limited to, data within the scope of federal and state laws and regulations governing the confidentiality of patient records and the privacy and security of health information promulgated by HIPAA. In the event the Hardware is stolen, lost, or compromised while in possession and control of you, you will be responsible to notify patients or other persons as may be required by law.</p>

                <p></p>

                <p><b>12.3.</b> Prior to return of the Hardware to PediaMetrix Inc., or any third party, including whether such return is for repair, at the end of the Term, or for any other reason, you will notify PediaMetrix Inc. and work with PediaMetrix Inc. Tech Support to remove and securely erase all PHI stored on such device before it is shipped. Upon receipt by PediaMetrix Inc. of Hardware, for any reason, you authorize PediaMetrix Inc. to examine the Hardware and securely erase all data, including PHI, stored on the Hardware.</p>

                <p></p>

                <p><b>13. Use of De-Identified Information</b></p>

                <p> <b>13.1.</b> In consideration of our provision of the Services, you hereby transfer and assign to us all right, title and interest in and to all De-Identified Information. &ldquo;De-Identified Information&rdquo; means information that has been de-identified in accordance with HIPAA. You agree that we may use, disclose, market, license and sell such De-Identified Information for any purpose without restriction, and that you have no interest in such information, or in the proceeds of any sale, license, or other commercialization thereof.</p>

                <p></p>

                <p><b>13.2.</b> You hereby grant to us a nonexclusive, royalty-free, fully paid-up, perpetual, irrevocable, worldwide and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display any information, material or work product (other than information which has not been De-Identified) you provide to PediaMetrix Inc. You agree that we may use, disclose, market, license, and sell such information and works, including derivative products, without restriction. This includes, for example, custom templates that you create using the Services, and information (other than information which has not been De-Identified) that you contribute to forums, discussion groups and the like. Furthermore, you agree that we may use, disclose, market, license and sell such material or content, and that you have no interest in the information, or in the proceeds of any sale, license, or other commercialization thereof. You warrant and agree that any material you provide will not infringe or otherwise violate the intellectual property or other rights of others, and will not be otherwise unlawful.</p>

                <p></p>

                <p><b>14. System Requirements, Support and Maintenance</b></p>

                <p> <b>14.1.</b> System requirements: You are responsible for the setup and maintenance of the internet connection for each device running SoftSpot&trade;. Internet connection must be a stable WIFI and/or 4G/LTE with minimum bandwidth of 10 Mbps (Megabits per second) and upload speeds of 5 Mbps</p>

                <p>You are responsible for all internet data services and charges &ndash; e.g., charges for the connection of each device running SoftSpot&trade; on which SoftSpot&trade; app is installed to the internet and for the internet network traffic of any computer system used to access SoftSpot&trade;.</p>

                <p></p>

                <p><b>14.2.</b> Help Desk. If the problem is with the Product(s) itself, the problem is referred to PediaMetrix Inc. If the problem is with either the user&rsquo;s personal computer or with its Internet connection, the problem is referred to your technical support group, which may be either in-house or outsourced. If the problem concerns diagnosis/procedure coding or other clinical decisions, it should be referred to your clinical policy group.</p>

                <p></p>

                <p><b>14.3. </b>Telephone and E-Mail Assistance. PediaMetrix Inc. will provide an initial response via telephone or e-mail within business hours to requests for assistance made by you. PediaMetrix Inc.&rsquo;s support business hours are 9:00 a.m. to 5:00 p.m.; Eastern Time. PediaMetrix Inc. will communicate a response or status within three (3) business days for any unresolved problems. PediaMetrix Inc.&rsquo;s assistance will consist of providing general advice on the operation and use of the Product(s) and assistance with suspected problems with the Product(s).</p>

                <p></p>

                <p><b>14.4. </b>Problem Correction. PediaMetrix Inc. will attempt to correct all documented problems in the Product(s) reported to PediaMetrix Inc. by you that PediaMetrix Inc. is able to recreate and will make any bug fixes available to you in a future release.</p>

                <p></p>

                <p><b>14.5.</b> Releases. PediaMetrix Inc. periodically prepares and makes available to you &ldquo;New Releases&rdquo; of the Product(s) that are generally made available by PediaMetrix Inc. to its clients. New Releases may include bug fixes; patches; improvements; or enhancements or additional features that are otherwise not separately marketed or priced. PediaMetrix Inc. may at its sole discretion designate certain improvements or enhancements as separate modules and charge a fee for such new functionality.</p>

                <p></p>

                <p><b>14.6.</b> Additional Responsibilities. You shall perform tasks as requested by PediaMetrix Inc. to aid in the resolution of problems, and shall implement all reasonable workarounds to problems as directed by PediaMetrix Inc.</p>

                <p></p>

                <p><b>15. Miscellaneous</b></p>

                <p> <b>15.1.</b> This Agreement, including all schedules and exhibits, constitutes the entire understanding we have with respect to this Agreement, and supersedes all prior or contemporaneous written and oral agreements. This Agreement may not be modified or amended except in writing executed by each party. Headings are for convenience only and will not be deemed a part of this Agreement.</p>

                <p></p>

                <p><b>15.2.</b> No waiver of any provision of this Agreement, or of any rights or obligations of any party, will be effective unless in writing and signed by the party waiving compliance.</p>

                <p></p>

                <p><b>15.3.</b> These terms and conditions and the transactions contemplated hereby shall be governed by, and construed and interpreted in accordance with the laws of Delaware, U.S.A, without regard to conflict of law provisions. The prevailing party in any judicial action to enforce this Agreement will be entitled to recover reasonable attorney fees incurred in such proceeding.</p>

                <p></p>

                <p><b>15.4.</b> All notices required or permitted hereunder will be in writing, and delivered personally, electronically, by telephonic facsimile or by an established national delivery service to the respective addresses set forth in the Invoice.</p>

                <p></p>

                <p><b>15.5.</b> Neither party will indirectly or directly transfer or assign any rights under this Agreement, in whole or part, without prior written consent of the other party. Notwithstanding, either party may, without prior written consent of the other party, assign this Agreement as part of a divestiture, reorganization or consolidation or to another party in connection with a merger, acquisition, or sale of substantially all assets or stock to which this Agreement relates, provided the successor agrees in writing to assume all of the assigning party&rsquo;s obligations hereunder.</p>

                <p></p>

                <p><b>15.6.</b> Each party is responsible for its own compliance with laws, regulations, and other legal requirements applicable to the conduct of its business and this Agreement and agrees to comply with all such laws, regulations and other legal requirements. You acknowledge SoftSpot&trade; is subject to the customs and export control laws and regulations of the United States and any country in which the products are manufactured, received or used. Further, under the laws of the United States, SoftSpot&trade; may not be sold, leased or otherwise transferred to restricted countries.</p>

                <p></p>

                <p><b>15.7. </b>We agree that no partnership, joint venture, or agency relationship exists between us as a result of this Agreement.</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TermsOfService;

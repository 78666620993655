import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Dashboard from "../PMScreens/PMDashboard/PMDashboard";
import PublicRoute from "./PublicRoute";
import PrivacyPolicy from "../PMScreens/PMPrivacyPolicy/PMPrivacyPolicy";
import TermsOfService from "../PMScreens/PMTermsOfService/PMTermsOfService";
import News from "../PMScreens/PMNews.js/PMNews";
import ContactUs from "../PMScreens/PMContactUs/PMContactUs";
import Disclaimer from "../PMScreens/PMDisclaimer/PMDisclaimer";
import Demo from "../PMScreens/PMDemo/PMDemo";
import DemoSurgeon from "../PMScreens/PMSurgeon/PMSurgeon";
import DemoParent from "../PMScreens/PMParent/PMParent";
import ParentHelpCenter from "../PMScreens/PMParentHelpCenter/PMParentHelpCenter";
import PhysicianHelpCenter from "../PMScreens/PMParentHelpCenter/PMPhysicianHelpCenter";
import DemoTherapist from "../PMScreens/PMTherapist/PMTherapist";
import BAA from "../PMScreens/PMBAA/PMBAA";

import DemoVideo from "../PMScreens/PMVideo/PMVideo";
import ReferralVideo from "../PMScreens/PMReferralVideo/PMReferralVideo";
import QualityPolicy from "../PMScreens/PMQualityPolicy/PMQualityPolicy";

const Routes = () => {
  return (
    <Router basename="/">
      <Switch>
        <PublicRoute path="/disclaimer" component={Disclaimer}
          title="SoftSpot App For iOS &amp; Android | Softspot Cranial Measurements App | PediaMetrix"
          description="PediaMetrix presents SoftSpot™, an innovative cranial measurement app for iOS &amp; Android. Get in touch with Us Now!." />
        <PublicRoute path="/news" component={News}
          title="SoftSpot App For iOS &amp; Android | Softspot Cranial Measurements App | PediaMetrix"
          description="PediaMetrix presents SoftSpot™, an innovative cranial measurement app for iOS &amp; Android. Get in touch with Us Now!." />
        <PublicRoute path="/contact-us" component={ContactUs}
          title="Softspot App | Contact Us | PediaMetrix"
          description="Contact PediaMetrix today. PediaMetrix offers a plagiocephaly self assessment application for cranial measurements and monitoring." />
         <PublicRoute path="/quality-policy" component={QualityPolicy}
          title="Quality Policy | SoftSpot | PediaMetrix"
          description="Check out the quality policy section of pediametrix.com." />
        <PublicRoute path="/privacy-policy" component={PrivacyPolicy}
          title="Privacy Policy | SoftSpot | PediaMetrix"
          description="Check out the privacy policy section of pediametrix.com. It is our sets forth the information gathering, use, and dissemination practices of PediaMetrix Inc." />
        <PublicRoute path="/terms-and-conditions" component={TermsOfService}
          title="Terms And Conditions | SoftSpot | PediaMetrix"
          description="Read the terms and conditions webpage of pediametrix.com. It provides brief info about the use and other information about SoftSpot mobile application." />
        <Route path="/demo" component={Demo} title="Demo | SoftSpot | PediaMetrix"
          description="PediaMetrix presents SoftSpot™, an innovative cranial measurement app for iOS &amp; Android. Get in touch with Us Now!." />
        <Route path="/baa" component={BAA} title="BAA | SoftSpot | PediaMetrix"
          description="PediaMetrix presents SoftSpot™, an innovative cranial measurement app for iOS &amp; Android. Get in touch with Us Now!." />
        <Route path="/demo-video" component={DemoVideo} title="Demo | SoftSpot | PediaMetrix"
          description="PediaMetrix presents SoftSpot™, an innovative cranial measurement app for iOS &amp; Android. Get in touch with Us Now!." />
        <Route path="/referral-video" component={ReferralVideo} title="Demo | SoftSpot | PediaMetrix"
          description="PediaMetrix presents SoftSpot™, an innovative cranial measurement app for iOS &amp; Android. Get in touch with Us Now!." />
        <Route path="/demo-surgeons" component={DemoSurgeon} title="Therapist | SoftSpot | PediaMetrix"
          description="PediaMetrix presents SoftSpot™, an innovative cranial measurement app for iOS &amp; Android. Get in touch with Us Now!." />
        <Route path="/demo-pt" component={DemoTherapist} title="Surgeons | SoftSpot | PediaMetrix"
          description="PediaMetrix presents SoftSpot™, an innovative cranial measurement app for iOS &amp; Android. Get in touch with Us Now!." />
        <Route path="/demo-parents" component={DemoParent} title="Parents | SoftSpot | PediaMetrix"
          description="PediaMetrix presents SoftSpot™, an innovative cranial measurement app for iOS &amp; Android. Get in touch with Us Now!." />
        <PublicRoute path="/parent-help-center" component={ParentHelpCenter} title="Parents | SoftSpot | PediaMetrix"
          description="PediaMetrix presents SoftSpot™, an innovative cranial measurement app for iOS &amp; Android. Get in touch with Us Now!." />
        <PublicRoute path="/physician-help-center" component={PhysicianHelpCenter} title="Parents | SoftSpot | PediaMetrix"
          description="PediaMetrix presents SoftSpot™, an innovative cranial measurement app for iOS &amp; Android. Get in touch with Us Now!." />

        <PublicRoute path='/softspot-login' component={() => {
          window.location.href = 'https://www.softspot-alpha.com/softspot-login';
          return null;
        }} />
        <Route path='/patient-instructions' component={() => {
          window.location.href = 'https://www.softspot-alpha.com/patient-instructions';
          return null;
        }} />
        <Route path='/patient-upload-photos' component={() => {
          window.location.href = 'https://www.softspot-alpha.com/patient-upload-photos';
          return null;
        }} />
        <Route path='/instructions-p' component={() => {
          window.location.href = 'https://www.softspot-alpha.com/instructions-p';
          return null;
        }} />
        <Route path='/results-p' component={() => {
          window.location.href = 'https://www.softspot-alpha.com/results-p';
          return null;
        }} />
        <Route path='/shop' component={() => {
          window.location.href = 'https://buy.stripe.com/eVaeYoda0cyK7vi9B6';
          return null;
        }} />
        <Route path='/shop-kit' component={() => {
          window.location.href = 'https://buy.stripe.com/5kAeYo1ri0Q216U28F';
          return null;
        }} />
        <Route path='/book-consultation' component={() => {
          window.location.href = 'https://www.softspot.online/book-online?dayful=sch';
          return null;
        }} />

        <PublicRoute path='/shop-mckesson' component={() => {
          window.location.href = 'https://mms.mckesson.com/product/1223079/Florida-Medical-Sales-PED-SOFTSPOT10';
          return null;
        }} />
        <PublicRoute path="/" exact component={Dashboard} restricted={true}
          title="SoftSpot Mobile App | Plagiocephaly and Cranial Measurements App | PediaMetrix"
          description="PediaMetrix offers the SoftSpot mobile app, the first FDA-cleared pediatric cranial measurement app for plagiocephaly and other head deformities. Sign up now for easy measurements and monitoring!" />
      </Switch>
    </Router>
  );
};

export default Routes;
